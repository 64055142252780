import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { AuthForgetPassActionTypes } from './types';
import {
  authForgetPassApiResponseSuccess,
  authForgetPassApiResponseError,
} from './actions';
import { recoverPassword, resetPassword } from '../../../api/';

function* forgetUser({ payload }: any) {
  const { email } = payload;

  try {
    const response: { data: any } = yield call(recoverPassword, email);
    yield put(
      authForgetPassApiResponseSuccess(
        AuthForgetPassActionTypes.FORGET_PASSWORD,
        response.data.message
      )
    );
  } catch (error) {
    yield put(
      authForgetPassApiResponseError(
        AuthForgetPassActionTypes.FORGET_PASSWORD,
        'Email not found. Please enter a valid email.'
      )
    );
  }
}

function* changePassword({ payload }: any) {
  const { newPassword, token } = payload;

  try {
    const response: { data: any } = yield call(resetPassword, newPassword, token);
    yield put(
      authForgetPassApiResponseSuccess(
        AuthForgetPassActionTypes.CHANGE_PASSWORD,
        response.data.message
      )
    );
  } catch (error) {
    yield put(
      authForgetPassApiResponseError(
        AuthForgetPassActionTypes.CHANGE_PASSWORD,
        'Failed to change password'
      )
    );
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(AuthForgetPassActionTypes.FORGET_PASSWORD, forgetUser);
}

export function* watchUserChangePassword() {
  yield takeEvery(AuthForgetPassActionTypes.CHANGE_PASSWORD, changePassword);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget), fork(watchUserChangePassword)]);
}

export default forgetPasswordSaga;
