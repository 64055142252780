import React, { useState } from "react";
import {
  Alert,
  Grid,
  Box,
  Container,
  Typography,
  Button,
  Modal,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Visibility, VisibilityOff, Home as HomeIcon } from "@mui/icons-material";
import NewPasswordLayout from "../../components/NewPasswordLayout";
import AuthHeader from "../../components/AuthHeader";
import Loader from "../../components/Loader";
import Cookies from "js-cookie";
import { URLS } from "../../constants";

interface FormValues {
  oldpassword: string;
  password: string;
  confirmpassword: string;
}

const CreateNewPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    oldpassword: false,
    password: false,
    confirmpassword: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [changepasswordError, setChangepasswordError] = useState<string | null>(
    null,
  );

  const resolver = yupResolver(
    yup.object().shape({
      oldpassword: yup.string().required(t("Please Enter Old Password.")),
      password: yup
        .string()
        .required(t("Please Enter New Password."))
        .min(8, t("Password must be at least 8 characters."))
        .matches(
          /[a-z]/,
          t("Password must contain at least one lowercase letter."),
        )
        .matches(
          /[A-Z]/,
          t("Password must contain at least one uppercase letter."),
        )
        .matches(/\d/, t("Password must contain at least one number."))
        .matches(
          /[@$!%*?&#]/,
          t("Password must contain at least one special character."),
        )
        .notOneOf(
          [yup.ref("oldpassword")],
          t("New Password must be different from Old Password."),
        ),
      confirmpassword: yup
        .string()
        .oneOf([yup.ref("password")], t("Passwords don't match"))
        .required(t("This value is required.")),
    }),
  );

  const defaultValues: FormValues = {
    oldpassword: "",
    password: "",
    confirmpassword: "",
  };

  const methods = useForm<FormValues>({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;
  const apiKey = Cookies.get("api-key");
  const onSubmitForm: SubmitHandler<FormValues> = async values => {
    setLoading(true);
    try {
      const email = Cookies.get("email");
      const response = await fetch(`${URLS.CLIENT_URL}/api/change-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          APIKEY: apiKey || "",
        },
        body: JSON.stringify({
          email: email,
          old_password: values.oldpassword,
          new_password: values.password,
        }),
      });

      if (response.ok) {
        setPasswordChanged(true);
        setTimeout(() => {
          navigate("/auth-login");
        }, 3000);
      } else {
        const errorData = await response.json();
        setChangepasswordError(
          errorData.message || "An error occurred while changing the password.",
        );
      }
    } catch (error) {
      setChangepasswordError("An error occurred while changing the password.");
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate("/auth-login");
  };

  const handleClickShowPassword = (field: keyof FormValues) => {
    setShowPassword(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <NewPasswordLayout>
      <Container maxWidth="sm">
        <Box py={5}>
          <AuthHeader title={t("Create New Password")} />
          {changepasswordError && (
            <Alert severity="error">
              {t("Error creating new password: ") + changepasswordError}
            </Alert>
          )}
          {passwordChanged && (
            <Alert severity="success">
              {t("Your password has been created successfully")}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
            {loading && <Loader />}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label={t("Old Password")}
                  type={showPassword.oldpassword ? "text" : "password"}
                  fullWidth
                  {...register("oldpassword")}
                  error={!!errors.oldpassword}
                  helperText={errors.oldpassword?.message}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#4e3699",
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickShowPassword("oldpassword")}
                        >
                          {showPassword.oldpassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("New Password")}
                  type={showPassword.password ? "text" : "password"}
                  fullWidth
                  {...register("password")}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#4e3699",
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickShowPassword("password")}
                        >
                          {showPassword.password ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("Confirm New Password")}
                  type={showPassword.confirmpassword ? "text" : "password"}
                  fullWidth
                  {...register("confirmpassword")}
                  error={!!errors.confirmpassword}
                  helperText={errors.confirmpassword?.message}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#4e3699",
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickShowPassword("confirmpassword")
                          }
                        >
                          {showPassword.confirmpassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Box textAlign="center" mt={4}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    sx={{
                      backgroundColor: "#4e3699",
                      "&:hover": {
                        backgroundColor: "#3d2c7d",
                      },
                    }}
                  >
                    {t("Save")}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    onClick={() => navigate("/auth-login")}
                  >
                    {t("Cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Container>


      <IconButton
        component={Link}
        to="/dashboard"
        sx={{
          position: "absolute",
          bottom: 16,
          left: 16,
          color: "white",
        }}
      >
        <HomeIcon />
      </IconButton>

      <Modal open={showModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            {t("Invalid Token")}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            {t(
              "The token is invalid or missing. You will be redirected to the login page.",
            )}
          </Typography>
          <Box textAlign="right" mt={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalClose}
            >
              {t("OK")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </NewPasswordLayout>
  );
};

export default CreateNewPassword;
