import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FaQuestionCircle } from "react-icons/fa";

interface QrCodeDisplayProps {
  qrCode: string;
}

export const QrCodeDisplay: React.FC<QrCodeDisplayProps> = ({ qrCode }) => {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div className="d-flex flex-column align-items-center position-relative">
      <img
        src={qrCode}
        alt="QR Code"
        style={{ width: "200px", height: "200px" }}
      />
      <Button
        id="tooltipButton"
        color="link"
        className="help-button"
        style={{
          position: "absolute",
          bottom: "-10px",
          right: "-135px",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0",
        }}
      >
        <FaQuestionCircle size={20} />
      </Button>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="tooltipButton"
        toggle={toggleTooltip}
      >
        <h2>{t("How to connect on WhatsApp")}</h2>
        <p>
          {t("Follow the instructions below to connect to WhatsApp using the QR code:")}
        </p>
        <ol>
          <li>{t("Open the WhatsApp app on your phone.")}</li>
          <li>{t("Go to settings and select 'WhatsApp Web/Desktop'.")}</li>
          <li>{t("Scan the QR code displayed on the screen with your phone.")}</li>
        </ol>
      </Tooltip>
    </div>
  );
};
