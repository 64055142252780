import React, { useState, useEffect, useRef } from "react";
import { Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import { TailSpin } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../../assets/images/logo-roxa.svg";
import { QrCodeDisplay } from "./QrCodeDisplay";
import "../../../assets/scss/custom.scss";
import { useTranslation } from "react-i18next";
import {
  connectWebSocket,
  handleWebSocketMessage,
  handleWebSocketError,
  StateType,
} from "../../../utils/webSocketUtils";
import { URLS } from "../../../constants";

interface WebhookQrModalProps {
  isOpen: boolean;
  toggle: () => void;
  existingSocket: WebSocket | null;
  qrCode: string | null;
  showQrCode: boolean;
  updateSessions?: () => void;
}

interface WebhookQrModalState extends StateType {}

export const WebhookQrModal: React.FC<WebhookQrModalProps> = ({
                                                                isOpen,
                                                                toggle,
                                                                existingSocket,
                                                                qrCode,
                                                                showQrCode,
                                                                updateSessions,
                                                              }) => {
  const { t } = useTranslation();
  const [state, setState] = useState<WebhookQrModalState>({
    loading: false,
    error: null,
    qrCode: qrCode,
    showQrCode: showQrCode,
    status: null,
    name: "",
    successMessage: null,
    attemptedSubmit: false,
    buttonBlocked: false,
    webSocketLoading: true,
    nameError: null,
  });

  const socket = useRef<WebSocket | null>(existingSocket);

  useEffect(() => {
    if (isOpen) {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      if (!socket.current) {
        socket.current = connectWebSocket(
          `${URLS.WEBSOCKET_URL}/api/ws`,
          (event) =>
            handleWebSocketMessage(
              event,
              setState,
              t("QR code successfully scanned!")
            ),
          (event) =>
            handleWebSocketError(
              event,
              setState,
              t("Failed to connect to the WebSocket. Please try again.")
            ),
          () => {
            console.log("WebSocket connection closed");
            if (updateSessions) {
              updateSessions();
            }
            handleToggle();
          }
        );
      } else {
        socket.current.onmessage = (event: MessageEvent) =>
          handleWebSocketMessage(
            event,
            setState,
            t("QR code successfully scanned!")
          );
        socket.current.onerror = (event: Event) =>
          handleWebSocketError(
            event,
            setState,
            t("Failed to connect to the WebSocket. Please try again.")
          );
        socket.current.onclose = () => {
          console.log("WebSocket connection closed");
          if (updateSessions) {
            updateSessions();
          }
          handleToggle();
        };
      }
    }
  }, [isOpen, t, updateSessions]);

  const handleToggle = () => {
    toggle();
    setState({
      loading: false,
      error: null,
      qrCode: null,
      showQrCode: false,
      status: null,
      name: "",
      successMessage: null,
      attemptedSubmit: false,
      buttonBlocked: false,
      webSocketLoading: true,
      nameError: null,
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} tabIndex={-1} centered scrollable>
      <ModalHeader toggle={toggle} className="bg-primary">
        <div
          className="modal-title-custom text-white font-size-16"
          style={{ color: "#ffff" }}
        >
          {t("Connect to Account")}
        </div>
      </ModalHeader>
      <ModalBody className="p-4">
        <Container className="d-flex flex-column align-items-center justify-content-center">
          <div id="logo" className="mt-4" style={{ paddingBottom: "10px" }}>
            <img src={logo} alt={t("Logo")} width={"350"} />
          </div>
          <h1 className="title-form" style={{ fontSize: "21px" }}>
            {t("Scan the QR code to connect")}
          </h1>
          {state.loading ? (
            <TailSpin height="80" width="80" color="#6c757d" />
          ) : state.showQrCode && state.qrCode ? (
            <QrCodeDisplay qrCode={state.qrCode} />
          ) : state.status === "WORKING" ? (
            <div>{t("Connected Successfully!")}</div>
          ) : (
            <div>{t("Waiting for QR code...")}</div>
          )}
          {state.error && (
            <div className="position-absolute top-0 start-50 translate-middle-x">
              <div className="alert alert-danger" role="alert">
                {state.error}
              </div>
            </div>
          )}
        </Container>
      </ModalBody>
      <ToastContainer />
    </Modal>
  );
};
