import React from "react";
import LanguageSelector from "../../components/LanguageSelector";

interface NonAuthLayoutProps {
  children: any;
}

const NonAuthLayout = (props: NonAuthLayoutProps) => {
  return (
    <div className="non-auth-layout-wrapper">
      {/* Language selector */}
      <LanguageSelector />
      {props.children}
    </div>
  );
};

export default NonAuthLayout;
