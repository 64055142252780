import React, { useEffect } from "react";
import { TabContent, TabPane } from "reactstrap";
import { useTranslation } from "react-i18next";
// hooks
import { useRedux } from "../../hooks";
import { createSelector } from "reselect";
// constants
import { TABS } from "../../constants";
// components
import Profile from "./Profile/index";
import Chats from "./Chats/index";
import StatusPage from "./Profile/StatusPage";
import { Session } from "./types";

interface LeftbarProps {}

const Leftbar = (props: LeftbarProps) => {
  const { t } = useTranslation();
  // global store
  const { useAppSelector, dispatch } = useRedux(); // usando dispatch diretamente
  const errorData = createSelector(
    (state: any) => state.Layout,
    state => ({
      activeTab: state.activeTab,
    }),
  );

  const { activeTab } = useAppSelector(errorData);

  // Forçando a tab inicial a ser a do StatusPage
  useEffect(() => {
    dispatch({ type: "SET_ACTIVE_TAB", payload: TABS.NEW_ACCOUNT });
  }, [dispatch]);

  const role = "admin";
  const sessions: Session[] = [];

  return (
    <>
      {/* start chat-leftsidebar */}
      <div className="chat-leftsidebar d-md-none">
        {/* TODO: Due to technical limitations, the active screen logic is currently not correctly set. Both the 'New Account' and 'Users' tabs are displaying the same content (StatusPage). This needs to be revisited and fixed to ensure the correct tab content is shown on the responsive mobile view. */}

        <TabContent activeTab={activeTab}>
          <TabPane tabId={TABS.NEW_ACCOUNT} role="tabpanel">
            <StatusPage role={role} sessions={sessions} />
          </TabPane>
          {/* Start Profile tab-pane */}
          <TabPane tabId={TABS.USERS} role="tabpanel">
            <StatusPage role={role} sessions={sessions} />
          </TabPane>

          <TabPane tabId={TABS.CHAT} role="tabpanel">
            <Chats />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default Leftbar;
