import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import DefaultLayout from "../src/layouts/Default";
import NonAuthLayout from "../src/layouts/NonAuth";
import { AuthProtected } from "./routes/AuthProtected";
import { publicRoutes, privateRoutes } from "./routes/allRoutes";
import { initGA, logPageView } from "../src/utils/analytics";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/scss/theme.scss";
import "./assets/scss/chat.scss";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  document.title = "Connect AI";

  // Criar um tema personalizado usando Poppins como fonte padrão
  const theme = createTheme({
    typography: {
      fontFamily: "Poppins, sans-serif",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {publicRoutes.map((route: any, idx: number) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
          />
        ))}

        {privateRoutes.map((route: any, idx: number) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                <DefaultLayout>{route.component}</DefaultLayout>
              </AuthProtected>
            }
            key={idx}
          />
        ))}
      </Routes>
    </ThemeProvider>
  );
};

export default App;
