import React, { useState, useEffect, useCallback } from 'react';
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

// hooks
import { useRedux } from "../../hooks/index";

// components
import Leftbar from "./Leftbar";
import StatusPage from "./Profile/StatusPage";
import { URLS } from "../../constants";
import FixedIcon from "./Ai/FixedIcons";
import axios from "axios";
import { Session } from "./types";

interface IndexProps {}

const Index = (props: IndexProps) => {
  const { useAppSelector } = useRedux();
  const { t } = useTranslation();
  const errorData = (state: any) => ({
    selectedChat: state.Chats.selectedChat,
    userProfile: state.Profile.userProfile,
  });
  const { selectedChat, userProfile } = useAppSelector(errorData);

  const [sessions, setSessions] = useState<Session[]>([]);
  const [isAIEnabled, setIsAIEnabled] = useState<boolean>(false);
  const apiKey = Cookies.get("api-key");
  const axiosInstance = axios.create({
    baseURL: URLS.CLIENT_URL,
    headers: {
      'Content-Type': 'application/json',
      APIKEY: apiKey
    },
  });

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await axiosInstance.get("/api/get-status");
        const result = response.data;

        const sessions = Array.isArray(result.session_status) ? result.session_status : [];

        setSessions(sessions);
        setIsAIEnabled(result.isAIEnabled);

      } catch (error) {
        console.error('Failed to fetch status:', error);
      }
    };

    fetchStatus();

    const intervalId = setInterval(() => {
      fetchStatus();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const userRole = userProfile?.role || 'admin';

  const updateSessions = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/api/get-status");
      const result = response.data;

      if (result.session_status && Array.isArray(result.session_status) && result.session_status.length > 0) {
        setSessions(result.session_status);
      } else {
        setSessions([]);
        console.error('session_status is not an array or empty:', result);
      }
    } catch (error) {
      console.error('Failed to update sessions:', error);
    }
  }, [axiosInstance]);

  const handleToggleAI = async () => {
    const newStatus = !isAIEnabled;
    try {
      await axiosInstance.post("/api/toggle-ai", { isAIEnabled: newStatus });
      setIsAIEnabled(newStatus);
    } catch (error) {
      console.error("Failed to update toggle status:", error);
    }
  };

  return (
    <>
      <Leftbar />
      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": selectedChat,
        })}
        id="user-chat"
      >
        <div className="user-chat-overlay" id="user-chat-overlay"></div>
        <StatusPage sessions={sessions} role={userRole} updateSessions={updateSessions} />
      </div>
      <FixedIcon isAIEnabled={isAIEnabled} toggleAI={handleToggleAI} />
    </>
  );
};

export default Index;
