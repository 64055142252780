import React, { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tooltip,
} from "reactstrap";


// interface
import { UserTypes } from "../../../data";
import { STATUS_TYPES } from "../../../constants";

interface ChatUserProps {
  user: UserTypes;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
}

const ChatUser = ({ user, selectedChat, onSelectChat }: ChatUserProps) => {
  const { t } = useTranslation();
  const fullName = `${user.firstName} ${user.lastName}`;
  const shortName = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;

  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));
  const isOnline = user.status && user.status === STATUS_TYPES.ACTIVE;
  const unRead = user.meta && user.meta.unRead;

  const isSelectedChat: boolean =
    selectedChat && selectedChat === user.id ? true : false;
  const onClick = () => {
    onSelectChat(user.id);
  };

  // Toggle state
  const [isToggled, setIsToggled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleToggle = (
    checked: boolean,
    event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
  ) => {
    event.stopPropagation();
    if (checked) {
      setIsModalOpen(true);
    } else {
      setIsToggled(false);
    }
  };

  const confirmToggle = () => {
    setIsToggled(true);
    setIsModalOpen(false);
  };

  const cancelToggle = () => {
    setIsToggled(false);
    setIsModalOpen(false);
  };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <li className={classnames({ active: isSelectedChat })} onClick={onClick}>
      <Link to="#" className={classnames({ "unread-msg-user": unRead })}>
        <div className="d-flex align-items-center">
          <div
            className={classnames(
              "chat-user-img",
              "align-self-center",
              "me-2",
              "ms-0",
              { online: isOnline },
            )}
          >
            {user.profileImage ? (
              <>
                <img
                  src={user.profileImage}
                  className="rounded-circle avatar-xs"
                  alt=""
                />
                {isOnline && <span className="user-status"></span>}
              </>
            ) : (
              <div className="avatar-xs">
                <span
                  className={classnames(
                    "avatar-title",
                    "rounded-circle",
                    "text-uppercase",
                    "text-white",
                    colors[color],
                  )}
                >
                  <span className="username">{shortName}</span>
                  <span className="user-status"></span>
                </span>
              </div>
            )}
          </div>
          <div className="overflow-hidden">
            <p className="text-truncate mb-0 user-name">{fullName}</p>
            <p className="text-truncate mb-0 message-preview">
              {user.meta?.lastMessageSent ? (
                <>
                  <i
                    className={`bx bx-check-double ${
                      user.meta?.lastMessageRead
                        ? "text-success"
                        : "text-secondary"
                    }`}
                  />{" "}
                  {user.meta.messagePreview}
                </>
              ) : (
                user.meta?.messagePreview
              )}
            </p>
          </div>
          <div className="ms-auto text-end d-flex flex-column align-items-end">
            {unRead && unRead !== 0 ? (
              <span className="badge badge-soft-dark rounded p-1 mb-1">
                {unRead}
              </span>
            ) : null}
            <p className="mb-0">{user.meta?.lastMessageTime}</p>
            <div
              id={`switchTooltip-${user.id}`}
              onClick={e => e.stopPropagation()}
            >
              <Switch
                onChange={handleToggle}
                checked={isToggled}
                offColor="#f3f3f3"
                onColor="#C6F602"
                height={20}
                width={40}
                handleDiameter={18}
                uncheckedIcon={false}
                checkedIcon={false}
                className="react-switch"
              />
            </div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target={`switchTooltip-${user.id}`}
              toggle={toggleTooltip}
            >
              {t("Disable AI")}
            </Tooltip>
          </div>
        </div>
      </Link>
      <Modal isOpen={isModalOpen} toggle={cancelToggle}>
        <ModalHeader toggle={cancelToggle}>{t("Confirm action")}</ModalHeader>
        <ModalBody>{t("Are you sure you want to disable your assistant?")}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmToggle}>
            {t("Yes")}
          </Button>{" "}
          <Button color="secondary" onClick={cancelToggle}>
            {t("No")}
          </Button>
        </ModalFooter>
      </Modal>
    </li>
  );
};

export default ChatUser;
