import axios, { AxiosResponse } from 'axios';
import { URLS } from '../constants';

const axiosInstance = axios.create({
  baseURL: URLS.CLIENT_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

interface ApiResponse<T> {
  data: T;
  status: number;
}

export const recoverPassword = (email: string): Promise<AxiosResponse<ApiResponse<any>>> => {
  return axiosInstance.post('/api/recover-password', { email });
};

export const resetPassword = (newPassword: any, token: string): Promise<AxiosResponse<ApiResponse<any>>> => {
  return axiosInstance.post(`/api/reset-password?token=${token}`, newPassword);
};
