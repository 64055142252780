import React from "react";
import { Alert, Row, Col, Form, Button, UncontrolledTooltip } from "reactstrap";
import { useTranslation } from "react-i18next";

// hooks
import { useRedux } from "../../hooks/index";

// router
import { Link, Navigate } from "react-router-dom";

// validations
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

// hooks
import { useProfile } from "../../hooks";
import { createSelector } from "reselect";
//actions
import { registerUser } from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "../../components/NonAuthLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";

interface RegisterProps {}
const Register = (props: RegisterProps) => {
  const { t } = useTranslation();

  // global store
  const { dispatch, useAppSelector } = useRedux();

  const errorData = createSelector(
    (state: any) => state.Register,
    (state) => ({
      user: state.user,
      registrationError: state.registrationError,
      regLoading: state.loading,
      isUserRegistered: state.isUserRegistered,
    })
  );
  // Inside your component
  const { user, registrationError, regLoading } = useAppSelector(errorData);

  const resolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .email(t("This value should be a valid email."))
        .required(t("Please enter your email.")),
      username: yup.string().required(t("Please enter your username.")),
      password: yup.string().required(t("Please enter your password.")),
    })
  );

  const defaultValues: any = {};

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const onSubmitForm = (values: object) => {
    dispatch(registerUser(values));
  };

  const { userProfile, loading } = useProfile();

  if (userProfile && !loading) {
    return <Navigate to={{ pathname: "/dashboard" }} />;
  }

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4 w-50">
          <div className="py-md-5 py-4">
            <AuthHeader title={t("Register your Account")} />

            {user && user ? (
              <Alert color="success">{t("User registered successfully")}</Alert>
            ) : null}

            {registrationError && registrationError ? (
              <Alert color="danger">{registrationError}</Alert>
            ) : null}

            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              className="position-relative"
            >
              {regLoading && <Loader />}
              <div className="mb-3">
                <FormInput
                  label={t("Email")}
                  type="text"
                  name="email"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder={t("Enter your email")}
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <FormInput
                  label={t("Username")}
                  type="text"
                  name="username"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder={t("Enter your username")}
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <FormInput
                  label={t("Password")}
                  type="password"
                  name="password"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  className="form-control pe-5"
                  placeholder={t("Enter your password")}
                />
              </div>

              <div className="mb-4">
                <p className="mb-0">
                  {t("By registering you agree to the")}{" "}
                  <Link
                    to="#"
                    className="text-primary"
                    style={{ color: "#4E3699" }}
                  >
                    {t("Terms of Use")}
                  </Link>{" "}
                  {t("of Connect.AI")}
                </p>
              </div>

              <div className="text-center mb-3">
                <Button
                  color="primary"
                  className="w-100  waves-effect waves-light"
                  type="submit"
                >
                  {t("Register")}
                </Button>
              </div>
              <div className="mt-4 text-center">
                <div className="signin-other-title">
                  <h5 className="font-size-14 mb-4 title">
                    {t("Sign up using")}
                  </h5>
                </div>
                <Row className="">
                  <div className="col-12">
                    <div>
                      <button
                        type="button"
                        className="btn btn-light w-100"
                        id="google"
                      >
                        <i className="mdi mdi-google text-danger"></i>
                      </button>
                    </div>
                    <UncontrolledTooltip placement="top" target="google">
                      Google
                    </UncontrolledTooltip>
                  </div>
                </Row>
              </div>
            </Form>

            <div className="mt-5 text-center text-muted">
              <p>
                {t("Already have an account?")}{" "}
                <Link
                  to="/auth-login"
                  className="fw-medium text-decoration-underline"
                  style={{ color: "#4E3699" }}
                >
                  {t("Login")}
                </Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default Register;
