import { AuthForgetPassActionTypes } from './types';

export const authForgetPassApiResponseSuccess = (actionType: string, data: any) => ({
  type: AuthForgetPassActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const authForgetPassApiResponseError = (actionType: string, error: string) => ({
  type: AuthForgetPassActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const userForgetPassword = (email: string) => ({
  type: AuthForgetPassActionTypes.FORGET_PASSWORD,
  payload: { email },
});

export const userChangePassword = (newPassword: any, token: string) => ({
  type: AuthForgetPassActionTypes.CHANGE_PASSWORD,
  payload: { newPassword, token },
});
