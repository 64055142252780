import React, { useState } from "react";
import { FaRobot } from "react-icons/fa";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input as BootstrapInput,
  Label,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import "./FixedIcon.scss";

interface FixedIconProps {
  isAIEnabled: boolean;
  toggleAI: () => void;
}

const FixedIcon: React.FC<FixedIconProps> = ({ isAIEnabled, toggleAI }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(true);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const closeTooltip = () => {
    setShowTooltip(false);
  };

  return (
    <div>
      {showTooltip && (
        <div className="tooltip-bubble" onClick={closeTooltip}>
          {t("ai_control_tooltip")}{" "}
          <span className="close-tooltip" onClick={closeTooltip}>
            <i className="bi bi-x"></i>
          </span>
        </div>
      )}
      <div className="fixed-icon" onClick={toggleModal}>
        <FaRobot size={30} color={isAIEnabled ? "green" : "red"} />
      </div>
      {!isAIEnabled && <div className="status-text">{t("ai_disabled")}</div>}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{t("ai_control")}</ModalHeader>
        <ModalBody>
          <FormGroup switch>
            <BootstrapInput
              type="switch"
              id="aiToggleSwitch"
              checked={isAIEnabled}
              onChange={toggleAI}
              style={{ transform: "scale(1.5)" }}
            />
            <Label check htmlFor="aiToggleSwitch">
              {isAIEnabled ? t("disable_ai") : t("enable_ai")}
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            {t("close")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FixedIcon;
