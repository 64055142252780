import React, { useState } from "react";
import {
  Alert,
  Grid,
  Box,
  Container,
  Typography,
  Button,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RecoverPasswordLayoutWrapper from "../../components/RecoverPasswordLayoutWrapper";
import Loader from "../../components/Loader";
import Cookies from "js-cookie";
import { URLS } from "../../constants";

interface FormValues {
  email: string;
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter your email."),
});

const defaultValues: FormValues = {
  email: "",
};

const RecoverPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [recoverPasswordError, setRecoverPasswordError] = useState<
    string | null
  >(null);
  const [recoverPasswordSuccess, setRecoverPasswordSuccess] = useState<
    string | null
  >(null);

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const apiKey = Cookies.get("api-key");
  const onSubmitForm = async (values: FormValues) => {
    setLoading(true);
    try {
      const response = await fetch(`${URLS.CLIENT_URL}/api/recover-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          APIKEY: apiKey || "",
        },
        body: JSON.stringify({
          email: values.email,
        }),
      });

      if (response.ok) {
        setRecoverPasswordSuccess(
          t("A password reset link has been sent to your email."),
        );
        setTimeout(() => {
          navigate("/auth-login");
        }, 3000);
      } else {
        const errorData = await response.json();
        setRecoverPasswordError(
          errorData.message ||
            t("An error occurred while sending the reset link."),
        );
      }
    } catch (error) {
      setRecoverPasswordError(
        t("An error occurred while sending the reset link."),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <RecoverPasswordLayoutWrapper>
      <Container maxWidth="sm">
        <Box py={5}>
          <Typography variant="h3" align="center" gutterBottom>
            {t("Change Password")}
          </Typography>
          {recoverPasswordError && (
            <Alert severity="error">{recoverPasswordError}</Alert>
          )}
          {recoverPasswordSuccess && (
            <Alert severity="success">{recoverPasswordSuccess}</Alert>
          )}
          <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
            {loading && <Loader />}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label={t("Email")}
                  type="email"
                  fullWidth
                  {...register("email")}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#4e3699",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Box textAlign="center" mt={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                sx={{
                  backgroundColor: "#4e3699",
                  "&:hover": {
                    backgroundColor: "#3d2c7d",
                  },
                }}
              >
                {t("Reset Password")}
              </Button>
            </Box>
          </form>
          <Box mt={5} textAlign="center">
            <Typography variant="body2" color="text.secondary">
              {t("Remember your password?")}{" "}
              <Button
                component="a"
                href="/auth-login"
                sx={{
                  textDecoration: "underline",
                  color: "#4e3699",
                  "&:hover": {
                    fontWeight: "bold",
                  },
                }}
              >
                {t("Login")}
              </Button>
            </Typography>
          </Box>
        </Box>
      </Container>
    </RecoverPasswordLayoutWrapper>
  );
};

export default RecoverPassword;
