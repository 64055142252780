import ReactGA from "react-ga4";

export const initGA = () => {
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
  if (trackingId) {
    ReactGA.initialize(trackingId);
    console.log("Google Analytics initialized with tracking ID:", trackingId);
  } else {
    console.error("Google Analytics tracking ID is not defined");
  }
};

export const logPageView = () => {
  const path = window.location.pathname + window.location.search;
  ReactGA.send({ hitType: "pageview", page: path });
  console.log(`Logged page view for ${path}`);
};
