import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Container,
  Label,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios, { AxiosError } from "axios";
import { TailSpin } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../../assets/images/logo-connect.svg";
import { QrCodeDisplay } from "./QrCodeDisplay";
import "../../../assets/scss/custom.scss";
import Cookies from "js-cookie";
import { URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import {
  ValidateName,
  containsEmoji,
  isNumeric,
  containsSpecialCharacters,
  isValidLength,
} from "../../../utils/validationutils";
import {
  connectWebSocket,
  handleWebSocketMessage,
  handleWebSocketError,
  handleWebSocketClose,
  StateType,
} from "../../../utils/webSocketUtils";

interface AccountNameFormProps {
  isOpen: boolean;
  toggle: () => void;
  updateSessions?: () => void;
}

export const AccountNameForm: React.FC<AccountNameFormProps> = ({
                                                                  isOpen,
                                                                  toggle,
                                                                  updateSessions,
                                                                }) => {
  const { t } = useTranslation();
  const [state, setState] = useState<StateType>({
    name: "",
    loading: false,
    error: null,
    successMessage: null,
    attemptedSubmit: false,
    buttonBlocked: false,
    qrCode: null,
    showQrCode: false,
    webSocketLoading: true,
    status: null,
    nameError: null,
  });

  const socket = useRef<WebSocket | null>(null);

  const URL = `${URLS.CLIENT_URL}/api/starting`;
  const WEBSOCKET_URL = `${URLS.WEBSOCKET_URL}/api/ws`;

  useEffect(() => {
    return () => {
      handleWebSocketClose(socket.current);
    };
  }, []);

  const handleDataSubmission = async () => {
    const apiKeyCookie = Cookies.get("api-key");
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: URL,
      headers: {
        "Content-Type": "application/json",
        APIKEY: apiKeyCookie,
      },
    };

    try {
      await axios.post(URL, { name: state.name }, config);
      setState((prevState) => ({
        ...prevState,
        successMessage: t("Your request has been successfully sent!"),
        buttonBlocked: false,
      }));

      socket.current = connectWebSocket(
        WEBSOCKET_URL,
        (event) =>
          handleWebSocketMessage(
            event,
            setState,
            t("QR code successfully scanned!")
          ),
        (event) =>
          handleWebSocketError(event, setState, t("WebSocket error observed")),
        (event) => {
          console.log("WebSocket connection closed");
          if (updateSessions) {
            updateSessions();
          }
          handleToggle();
        }
      );
    } catch (error) {
      console.error("Error during submission:", error);
      setState((prevState) => ({
        ...prevState,
        error: t(
          axios.isAxiosError(error) && error.response?.status === 409
            ? "This name already exists!"
            : "An error occurred. Please try again."
        ),
        loading: false,
        buttonBlocked: false,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      attemptedSubmit: true,
    }));

    if (
      state.loading ||
      state.buttonBlocked ||
      state.name.trim().length < 3 ||
      containsEmoji(state.name) ||
      isNumeric(state.name)
    ) {
      return;
    }

    const name = state.name.trim();
    const errorMessage = ValidateName(name, t);

    if (errorMessage) {
      setState((prevState) => ({
        ...prevState,
        nameError: t(errorMessage),
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      loading: true,
      buttonBlocked: true,
      error: null,
    }));

    handleDataSubmission();
  };

  const handleToggle = () => {
    toggle();
    setState({
      name: "",
      loading: false,
      error: null,
      successMessage: null,
      attemptedSubmit: false,
      buttonBlocked: false,
      qrCode: null,
      showQrCode: false,
      webSocketLoading: true,
      status: null,
      nameError: null,
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} tabIndex={-1} centered scrollable>
      <ModalHeader toggle={toggle} className="account-header">
        <div
          className="modal-title-custom text-white font-size-16"
          style={{ color: "#ffff" }}
        >
          {t("Create Account")}
        </div>
      </ModalHeader>
      <ModalBody className="p-4">
        <Container className="d-flex flex-column align-items-center justify-content-center">
          <div id="logo" className="mt-4" style={{ paddingBottom: "10px" }}>
            <img src={logo} alt={t("Logo")} width={"350"} />
          </div>
          <h1 className="title-form" style={{fontSize: '21px'}}>
            {t("Enter your new account here")}
          </h1>
          {state.loading ? (
            <TailSpin height="80" width="80" color="#6c757d"/>
          ) : state.showQrCode && state.qrCode ? (
            <QrCodeDisplay qrCode={state.qrCode}/>
          ) : state.status === "WORKING" ? (
            <div>{t("Connected Card")}</div>
          ) : (
            <>
              {state.successMessage && (
                <div className="position-absolute top-0 start-50 translate-middle-x">
                  <div className="alert alert-success" role="alert">
                    {state.successMessage}
                  </div>
                </div>
              )}
              <Form onSubmit={handleSubmit} className="w-100">
                <FormGroup className="form-floating">
                  <Input
                    type="text"
                    id="accountName"
                    name="accountName"
                    placeholder={t("Enter account name")}
                    value={state.name}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                        nameError: null,
                      }))
                    }
                    invalid={
                      state.attemptedSubmit &&
                      (state.name.trim().length < 3 ||
                        containsEmoji(state.name) ||
                        containsSpecialCharacters(state.name) ||
                        !isValidLength(state.name))
                    }
                    className="form-control"
                  />
                  <Label for="accountName">{t("Account Name")}</Label>
                  <FormFeedback>
                    {t("The account name must be at least 3 characters long and cannot contain emojis or special characters.")}
                  </FormFeedback>
                </FormGroup>
                <FormGroup className="text-center mt-3">
                  <Button
                    type="submit"
                    disabled={state.loading || state.buttonBlocked}
                    color="primary"
                    style={{
                      cursor:
                        state.loading || state.buttonBlocked
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    {t("Save")}
                  </Button>
                </FormGroup>
              </Form>

            </>
          )}
          {state.error && (
            <div className="position-absolute top-0 start-50 translate-middle-x">
              <div className="alert alert-danger" role="alert">
                {state.error}
              </div>
            </div>
          )}
        </Container>
      </ModalBody>
      <ToastContainer />
    </Modal>
  );
};
