import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/logo-branca.svg";

interface NonAuthLayoutWrapperProps {
  children: React.ReactNode;
}

const NonAuthLayoutWrapper: React.FC<NonAuthLayoutWrapperProps> = ({
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className="auth-bg d-flex flex-column min-vh-100">
      <Container
        fluid
        className="p-0 h-100 d-flex justify-content-center align-items-center"
      >
        <Row className="h-100 w-100 d-flex justify-content-center align-items-center">
          <Col
            md={5}
            className="d-flex flex-column align-items-center justify-content-center logo-aligned"
            style={{ zIndex: 1 }}
          >
            <div className="welcome-section text-center text-md-start">
              <div className="logo-section mb-4">
                <img src={logo} alt="Logo" className="logo-img" />
              </div>
              <div className="welcome">
                <h1>
                  <span className="highlight">{t("hello")}</span>{" "}
                  {t("greeting")}
                  {t("registerOrLogin")}
                </h1>
                <p></p>
              </div>
            </div>
            <div
              className="footer-text mt-auto"
              style={{ color: "white", textAlign: "center" }}
            >
              <a
                href="https://ittech.digital/"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link footer-text"
              >
                &copy;It Tech
              </a>
            </div>
          </Col>
          <Col
            md={7}
            className="d-flex align-items-center justify-content-center"
            style={{ zIndex: 2 }}
          >
            <div className="authentication-page-content">{children}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NonAuthLayoutWrapper;
