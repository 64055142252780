import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemIcon, Tooltip } from "@mui/material";
import { useRedux } from "../../hooks";
import { changeTab } from "../../redux/actions";
import { TABS } from "../../constants";
import LightDarkMode from "../../components/LightDarkMode";
import { getMenuItems, MenuItemType } from "./menu";
import logo from "../../assets/images/logo-icon-branca.svg";
import { useTranslation } from 'react-i18next';
import ProfileDropdownMenu from "../../components/ProfileDropdownMenu";
import { Link } from "react-router-dom";

const LogoLightSVG = () => {
  return <img src={logo} width="30px" alt="Logo" />;
};

const LogoDarkSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
    </svg>
  );
};

const Logo = () => {
  return (
    <div className="navbar-brand-box">
      <Link to="#" className="logo logo-dark">
        <span className="logo-sm">
          <LogoLightSVG />
        </span>
      </Link>
      <Link to="#" className="logo logo-dark">
        <span className="logo-sm">
          <LogoDarkSVG />
        </span>
      </Link>
    </div>
  );
};

interface MenuNavItemProps {
  item: MenuItemType;
  selectedTab: TABS.NEW_ACCOUNT | TABS.USERS | TABS.CHAT;
  onChangeTab: (id: TABS.NEW_ACCOUNT | TABS.USERS | TABS.CHAT) => void;
}

const MenuNavItem: React.FC<MenuNavItemProps> = ({ item, selectedTab, onChangeTab }) => {
  const onClick = () => {
    onChangeTab(item.tabId);
  };

  return (
    <Tooltip title={item.tooltipTitle} placement="right" arrow>
      <ListItem
        button
        selected={selectedTab === item.tabId}
        onClick={onClick}
        sx={{ display: 'flex', justifyContent: 'center', padding: '24px 0' }}
      >
        <ListItemIcon sx={{ color: 'white', fontSize: 24 }}>
          <i className={item.icon}></i>
        </ListItemIcon>
      </ListItem>
    </Tooltip>
  );
};

interface SideMenuProps {
  onChangeLayoutMode: (value: any) => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ onChangeLayoutMode }) => {
  const { dispatch, useAppSelector } = useRedux();
  const { t } = useTranslation();
  const menuItems: MenuItemType[] = getMenuItems(t);
  const { activeTab, layoutMode } = useAppSelector(state => ({
    activeTab: state.Layout.activeTab,
    layoutMode: state.Layout.layoutMode,
  }));

  const [selectedTab, setSelectedTab] = useState<TABS.NEW_ACCOUNT | TABS.CHAT | TABS.USERS>(TABS.USERS);

  const onChangeTab = (id: TABS.NEW_ACCOUNT | TABS.USERS | TABS.CHAT) => {
    setSelectedTab(id);
    dispatch(changeTab(id));
  };

  useEffect(() => {
    setSelectedTab(activeTab);
  }, [activeTab]);

  return (
    <div className="side-menu flex-lg-column">
      {/* LOGO */}
      <Logo />



      <div className="flex-lg-column my-0 sidemenu-navigation">
        <List sx={{ padding: 0 }}>
          <ProfileDropdownMenu />
          <Tooltip title={t("Download")} placement="right" arrow>
            <ListItem button component={Link} to="/download" sx={{ justifyContent: 'center', padding: '12px 0' }}>
              <ListItemIcon sx={{ color: 'white', fontSize: 24, marginLeft: '25px' }}>
                <i className="bx bx-download"></i>
              </ListItemIcon>
            </ListItem>
          </Tooltip>
          {/* change mode */}
          <LightDarkMode
            layoutMode={layoutMode}
            onChangeLayoutMode={onChangeLayoutMode}
          />
        </List>
      </div>

    </div>
  );
};

export default SideMenu;
