import { AuthForgetPassActionTypes, AuthForgetPassState } from './types';

export const INIT_STATE: AuthForgetPassState = {
  loading: false,
  successMsg: null,
  error: null,
  emailSent: false,
  passwordChanged: false,
};

const ForgetPassword = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case AuthForgetPassActionTypes.API_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        successMsg: action.payload.data,
        emailSent: action.payload.actionType === AuthForgetPassActionTypes.FORGET_PASSWORD,
        passwordChanged: action.payload.actionType === AuthForgetPassActionTypes.CHANGE_PASSWORD,
      };

    case AuthForgetPassActionTypes.API_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        successMsg: null,
        error: action.payload.error,
      };

    case AuthForgetPassActionTypes.FORGET_PASSWORD:
      return {
        ...state,
        loading: true,
        successMsg: null,
        error: null,
      };

    case AuthForgetPassActionTypes.CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
        successMsg: null,
        error: null,
      };

    default:
      return { ...state };
  }
};

export default ForgetPassword;
