import {TFunction} from "i18next"

export const containsEmoji = (text: string) => {
    const emojiRegex =
        /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
    return emojiRegex.test(text);
};

export const isNumeric = (text: string) => {
    return /^\d+$/.test(text);
};

export const containsSpecialCharacters = (text: string) => {
    const specialCharRegex = /[^a-zA-Z0-9-_]/g;
    return specialCharRegex.test(text);
};

export const isValidLength = (name: string) => name.length >= 3 && name.length <= 30;
const doesNotContainEmoji = (name: string) => !containsEmoji(name);
const isNotNumeric = (name: string) => !isNumeric(name);
const doesNotContainSpecialCharacters = (name: string) => !containsSpecialCharacters(name);

export const ValidateName = (name: string, t: TFunction) => {
    if (!isValidLength(name)) {
        return t("The account name must be between 3 and 30 characters long.");
    }
    if (!doesNotContainEmoji(name)) {
        return t("The account name cannot contain emojis.");
    }
    if (!isNotNumeric(name)) {
        return t("The account name cannot be composed solely of numbers.");
    }
    if (!doesNotContainSpecialCharacters(name)) {
        return t("The account name cannot contain special characters.");
    }

    return null;
};
