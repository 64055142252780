import React, { useState } from "react";
import { Menu, MenuItem, IconButton, Tooltip } from "@mui/material";
import CustomModal from "../components/CustomModal";
import { useProfile } from "../hooks";
import { useTranslation } from "react-i18next";

import PersonIcon from "@mui/icons-material/Person";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ProfileDropdownMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const { userProfile } = useProfile();
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("profile")} placement="right" arrow>
        <IconButton
          onClick={handleClick}
          className="bg-transparent"
          sx={{ borderRadius: "16px" }}
        >
          <AccountCircleIcon fontSize="large" className="text-muted" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ "& .MuiPaper-root": { borderRadius: "16px" } }}
      >
        <MenuItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => {
            toggleModal();
            handleClose();
          }}
          sx={{ borderRadius: "12px" }}
        >
          {t("viewProfile")}{" "}
          <PersonIcon fontSize="small" className="text-muted ms-1" />
        </MenuItem>

        <MenuItem
          className="d-flex align-items-center justify-content-between"
          onClick={handleClose}
          component="a"
          href="/auth-create-new-password"
          sx={{ borderRadius: "12px" }}
        >
          {t("changePassword")}{" "}
          <LockOpenIcon fontSize="small" className="text-muted ms-1" />
        </MenuItem>

        <MenuItem
          className="d-flex align-items-center justify-content-between"
          onClick={handleClose}
          component="a"
          href="/logout"
          sx={{ borderRadius: "12px" }}
        >
          {t("logout")}{" "}
          <ExitToAppIcon fontSize="small" className="text-muted ms-1" />
        </MenuItem>
      </Menu>

      <CustomModal
        isOpen={modalOpen}
        toggle={toggleModal}
        title={t("userProfile")}
      >
        <div>
          <p>
            {t("hello")} <strong>{userProfile?.userName || t("user")}</strong>,{" "}
            {t("currentlyLoggedIn")}{" "}
            <strong>{userProfile?.companyName || t("company")}</strong>.
          </p>
        </div>
      </CustomModal>
    </>
  );
};

export default ProfileDropdownMenu;
