import React from 'react';
import '../assets/scss/UserModal.scss';

interface CustomModalProps {
  isOpen: boolean;
  toggle: () => void;
  title: string;
  children: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, toggle, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="custom-modal">
      <div className="custom-modal-content">
        <div className="custom-modal-header modal-header-colored"> 
          <h4>{title}</h4>
          <button onClick={toggle} className="close-button">&times;</button>
        </div>
        <div className="custom-modal-body">
          {children}
        </div>
      </div>
      <div className="custom-modal-backdrop" onClick={toggle}></div>
    </div>
  );
};

export default CustomModal;
