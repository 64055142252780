import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from "./locales/English.json";
import ptTranslation from "./locales/Portuguese.json";
import esTranslation from "./locales/Spanish.json";

const getSavedLanguage = () => {
  const savedLanguage = localStorage.getItem("language");
  return savedLanguage || "en";
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    pt: {
      translation: ptTranslation,
    },
    es: {
      translation: esTranslation,
    },
  },
  lng: getSavedLanguage(),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
  localStorage.setItem("language", language);
};

export default i18n;
