import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import { FaWindows, FaLinux, FaApple } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomeIcon from "@mui/icons-material/Home";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/images/logo-branca.svg";
import platformPreview from "../../assets/images/download-icon.png";

const DownloadPage: React.FC = () => {
  const { t } = useTranslation();
  const [os, setOs] = useState<string>("");

  useEffect(() => {
    const platform = window.navigator.platform.toLowerCase();
    if (platform.includes("win")) {
      setOs("Windows");
    } else if (platform.includes("mac")) {
      setOs("Mac");
    } else if (platform.includes("linux")) {
      setOs("Linux");
    } else {
      setOs(t("downloadPage_unknownOs"));
    }
  }, [t]);

  useEffect(() => {
    const hasSeenWarning = localStorage.getItem("seenMacLinuxWarning");

    if (!hasSeenWarning && (os === "Mac" || os === "Linux")) {
      toast.info(t("downloadPage_macLinuxComingSoonMessage"));
      localStorage.setItem("seenMacLinuxWarning", "true");
    }
  }, [os, t]);

  const handleLinkClick = (osType: string, event: React.MouseEvent) => {
    if (osType === "Mac" || osType === "Linux") {
      event.preventDefault();
      toast.info(t("downloadPage_macLinuxComingSoonMessage"));
    }
  };

  const downloadLinks: { [key: string]: string } = {
    Windows: "https://connectai.digital/ConnectAI.exe",
    Mac: "https://connectai.digital/ConnectAI.dmg",
    Linux: "https://connectai.digital/ConnectAI.tar.gz",
  };

  const renderIcon = () => {
    if (os === "Windows") {
      return <FaWindows style={{ marginRight: 8 }} />;
    } else if (os === "Linux") {
      return <FaLinux style={{ marginRight: 8 }} />;
    } else if (os === "Mac") {
      return <FaApple style={{ marginRight: 8 }} />;
    }
    return null;
  };

  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #4e3699 45%, #60bfb7 100%)",
        color: "white",
        minHeight: "100vh",
        p: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        {/* empty column to put some space to the margin*/}
        <Grid item xs={false} md={1} />

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            textAlign: "justify",
          }}
        >
          <img
            src={logo}
            alt={t("downloadPage_logoAlt")}
            style={{ width: "150px", marginBottom: "20px" }}
          />
          <Typography variant="h4" gutterBottom style={{ color: "#ffffff" }}>
            {t("downloadPage_greeting")}
          </Typography>

          <Button
            style={{ color: "#000" }}
            variant="contained"
            color="primary"
            size="large"
            sx={{
              mt: 3,
              mb: 2,
              width: "100%",
              maxWidth: "300px",
              backgroundColor: "#93f090",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            href={
              downloadLinks[os] || "https://connectai.digital/ConnectAI.exe"
            }
          >
            {renderIcon()}
            {t("downloadPage_downloadButton", { os })}
          </Button>

          <Typography variant="body2">
            {t("downloadPage_availableFor")}{" "}
            {os !== "Mac" && (
              <Link
                to="#"
                onClick={e => handleLinkClick("Mac", e)}
                style={{ color: "#93f090" }}
              >
                Mac
              </Link>
            )}{" "}
            {os !== "Linux" && (
              <>
                {os === "Mac" ? t("downloadPage_and") : " "}
                <Link
                  to="#"
                  onClick={e => handleLinkClick("Linux", e)}
                  style={{ color: "#93f090" }}
                >
                  Linux
                </Link>
              </>
            )}
            {os !== "Windows" && (
              <>
                {os !== "Linux" && os !== "Mac" ? t("downloadPage_and") : " "}
                <Link
                  to="#"
                  onClick={e => handleLinkClick("Windows", e)}
                  style={{ color: "#93f090" }}
                >
                  Windows
                </Link>
              </>
            )}
            .
          </Typography>

          <Typography
            variant="caption"
            sx={{ mt: 2, display: "block", color: "#f2f2f2" }}
          >
            {t("downloadPage_downloadExecutable")}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            {t("downloadPage_afterDownload")}{" "}
            <a
              href="https://api.whatsapp.com/send/?phone=556199464630&text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es.&type=phone_number&app_absent=0"
              style={{ color: "#93f090" }}
            >
              {t("downloadPage_support")}
            </a>
            .
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={platformPreview}
            alt={t("downloadPage_platformPreviewAlt")}
            style={{ maxWidth: "100%", borderRadius: "8px" }}
          />
        </Grid>
      </Grid>

      <IconButton
        component={Link}
        to="/dashboard"
        sx={{
          position: "absolute",
          bottom: 16,
          left: 16,
          color: "white",
        }}
      >
        <HomeIcon />
      </IconButton>

      <ToastContainer position="top-right" autoClose={5000} />
    </Box>
  );
};

export default DownloadPage;
