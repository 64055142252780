import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Badge,
} from "reactstrap";
import { FaWhatsapp, FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import menuIcon from "../../../assets/images/menu-card.png";
import searchIcon from "../../../assets/images/search.svg";
import qrIcon from "../../../assets/images/qr-code.svg";
import whatsappIcon from "../../../assets/images/whatsapp.svg";
import "../../../assets/scss/custom/pages/_status-page.scss";
import { URLS } from "../../../constants";
import { toast, ToastContainer } from "react-toastify";
import { AccountNameForm } from "./NewProfile";
import emptyStateImage from "../../../assets/images/empty.svg";
import { Session } from "../types";
import { WebhookQrModal } from "./GetQRCode";
import {
  connectWebSocket,
  handleWebSocketMessage,
  handleWebSocketError,
  StateType,
} from '../../../utils/webSocketUtils';
import Cookies from "js-cookie";

interface StatusPageProps {
  role: string;
  sessions: Session[];
  updateSessions?: () => void;
}

const StatusPage: React.FC<StatusPageProps> = ({ sessions, role, updateSessions }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredSessions, setFilteredSessions] = useState<Session[]>([]);
  const [accountModalOpen, setAccountModalOpen] = useState<boolean>(false);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [sessionToDelete, setSessionToDelete] = useState<Session | null>(null);
  const [activeTab, setActiveTab] = useState<string>("1");
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [state, setState] = useState<StateType>({
    qrCode: null,
    showQrCode: false,
    webSocketLoading: true,
    loading: false,
    status: null,
    error: null,
  });

  useEffect(() => {
    setFilteredSessions(sessions);
  }, [sessions]);

  const formatPhoneNumber = (phoneNumber: string) => {
    let countryCode, areaCode, mainNumber, formattedNumber;

    if (phoneNumber.startsWith("971")) {
      countryCode = "971";
      areaCode = phoneNumber.slice(3, 5);
      mainNumber = phoneNumber.slice(5);
      formattedNumber = mainNumber.length === 7
        ? `${mainNumber.slice(0, 3)}-${mainNumber.slice(3)}`
        : `${mainNumber.slice(0, 4)}-${mainNumber.slice(4)}`;
      return `+${countryCode} ${areaCode} ${formattedNumber}`;
    } else if (phoneNumber.startsWith("55")) {
      countryCode = "55";
      const numberWithoutCountryCode = phoneNumber.slice(2);
      areaCode = numberWithoutCountryCode.slice(0, 2);
      mainNumber = numberWithoutCountryCode.slice(2);
      formattedNumber = mainNumber.length === 8
        ? `${mainNumber.slice(0, 4)}-${mainNumber.slice(4)}`
        : `${mainNumber.slice(0, 5)}-${mainNumber.slice(5)}`;
      return `+${countryCode} ${areaCode} ${formattedNumber}`;
    } else if (phoneNumber.startsWith("1")) {
      countryCode = "1";
      const numberWithoutCountryCode = phoneNumber.slice(1);
      areaCode = numberWithoutCountryCode.slice(0, 3);
      mainNumber = numberWithoutCountryCode.slice(3);
      formattedNumber = `${mainNumber.slice(0, 3)}-${mainNumber.slice(3)}`;
      return `+${countryCode} (${areaCode}) ${formattedNumber}`;
    } else {
      return phoneNumber;
    }
  };

  const toggleDropdown = (sessionName: string) => {
    setDropdownOpen(prevState => (prevState === sessionName ? null : sessionName));
  };
  const apiKey = Cookies.get("api-key");
  const handleDeleteSession = async () => {
    if (sessionToDelete) {
      setLoading(true);
      try {
        const response = await fetch(
          `${URLS.CLIENT_URL}/api/session_delete`,
          {
            method: "POST",
            headers: {
              APIKEY: apiKey || "",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ name: sessionToDelete.name }),
          },
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setMessage(t("Session deleted successfully!"));
        setSessionToDelete(null);
        setConfirmModalOpen(false);
        if (updateSessions) {
          updateSessions();
        }
      } catch (error) {
        console.error("Failed to delete session:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const confirmDeleteSession = (session: Session) => {
    setSessionToDelete(session);
    setConfirmModalOpen(true);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
    if (sessions) {
      if (searchValue === "") {
        setFilteredSessions(sessions);
      } else {
        const filtered = sessions.filter(session =>
          session.name.toLowerCase().includes(searchValue.toLowerCase()),
        );
        setFilteredSessions(filtered);
      }
    }
  };

  const handleSearchClick = () => {
    if (sessions) {
      const filtered = sessions.filter(session =>
        session.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredSessions(filtered);
    }
  };

  const toggleAccountModal = () => {
    if (sessions.length >= 4) {
      toast.error(t('Session limit'));
    } else {
      setAccountModalOpen(!accountModalOpen);
    }
  };

  const toggleQrCodeModal = () => {
    if (sessions.length >= 4) {
      toast.error(t('Session limit'));
    } else {
      if (!qrCodeModalOpen) {
        const newSocket = connectWebSocket(
          URLS.WEBSOCKET_URL + '/api/ws',
          (event) => handleWebSocketMessage(event, setState, t("QR code successfully scanned!")),
          (event) => handleWebSocketError(event, setState, t("WebSocket error observed"))
        );
        setSocket(newSocket);
      } else {
        if (socket) {
          socket.close();
          setSocket(null);
        }
      }
      setQrCodeModalOpen(!qrCodeModalOpen);
    }
  };

  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  return (
    <Container className="card-status">
      <ToastContainer />
      <Card className="container-card">
        <CardBody>
          <div className="header">
            <div className="d-flex flex-column align-items-center mb-2">
              <div className="d-flex align-items-center w-75 mb-1">
                <InputGroup className="search-bar flex-grow-1">
                  <Input
                    type="text"
                    placeholder={t("Search sessions...")}
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  <InputGroupText>
                    <Button
                      color="link"
                      onClick={handleSearchClick}
                      style={{ padding: 0 }}
                    >
                      <img
                        src={searchIcon}
                        alt={t("search")}
                        style={{ width: "16px", height: "16px" }}
                      />
                    </Button>
                  </InputGroupText>
                </InputGroup>
                {role === "admin" && (
                  <Button
                    color="link"
                    onClick={toggleAccountModal}
                    className="btn btn-primary add-session-btn d-flex align-items-center"
                  >
                    <FaWhatsapp style={{ width: "24px", height: "24px" }} />
                    <FaPlus
                      style={{
                        width: "24px",
                        height: "24px",
                        marginLeft: "8px",
                      }}
                    />
                  </Button>
                )}
              </div>
              {filteredSessions.length === 0 && searchTerm && (
                <div
                  className="d-flex justify-content-start w-75"
                  style={{ color: "red", fontSize: "14px", marginTop: "4px" }}
                >
                  {t("No results found")}
                </div>
              )}
            </div>
          </div>
          <Row>
            {filteredSessions.length > 0 ? (
              filteredSessions.map((session, index) => (
                <Col xs="12" md="6" key={index} className="mb-4">
                  <Card
                    className="shadow-sm border"
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "inherit",
                    }}
                  >
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <CardBody className="d-flex align-items-center">
                          <img
                            src={whatsappIcon}
                            alt="WhatsApp icon"
                            className="profile-img icon-sm me-3"
                            style={{ width: "30px", height: "40px" }}
                          />
                          <div className="flex-grow-1">
                            <span className="mb-1">
                              {truncateText(session.name, 15)}
                            </span>
                            {session.phone_number && (
                              <div style={{ fontSize: "12px", color: "#6c757d", paddingBottom: "2px" }}>
                                {formatPhoneNumber(session.phone_number)}
                              </div>
                            )}
                            <div className="d-flex align-items-center">
                              <Badge
                                color={
                                  session.status === "WORKING"
                                    ? "success"
                                    : session.status === "STOPPED"
                                      ? "danger"
                                      : session.status === "SCAN_QR_CODE"
                                        ? "warning"
                                        : session.status === "STARTING"
                                          ? "primary"
                                          : "secondary"
                                }
                                className="me-2"
                                style={{
                                  padding: "0.2em 0.4em",
                                  backgroundColor:
                                    session.status === "SCAN_QR_CODE"
                                      ? "#FFA500"
                                      : session.status === "STARTING"
                                        ? "#8A2BE2"
                                        : ""
                                }}
                              >
                                {session.status === "WORKING"
                                  ? t("Connected")
                                  : session.status === "STOPPED"
                                    ? t("Disconnected")
                                    : session.status === "SCAN_QR_CODE"
                                      ? t("Scan QR Code")
                                      : session.status === "STARTING"
                                        ? t("Starting")
                                        : t("Disconnected")}
                              </Badge>
                            </div>
                          </div>
                          {role === "admin" && (
                            <>
                              {session.status === "SCAN_QR_CODE" && (
                                <Button
                                  color="link"
                                  onClick={toggleQrCodeModal}
                                  className="add-session-btn"
                                >
                                  <img
                                    src={qrIcon}
                                    alt={t("add")}
                                    style={{ width: "24px", height: "24px" }}
                                  />
                                </Button>
                              )}
                              <Dropdown
                                isOpen={dropdownOpen === session.name}
                                toggle={() => toggleDropdown(session.name)}
                              >
                                <DropdownToggle className="btn-menu" caret>
                                  <img src={menuIcon} alt="Menu" />
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <DropdownItem onClick={() => confirmDeleteSession(session)}>
                                    {t("Delete session")}
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </>
                          )}
                        </CardBody>
                      </TabPane>
                    </TabContent>
                  </Card>
                </Col>
              ))
            ) : (
              <div className="text-center my-5">
                <img
                  src={emptyStateImage}
                  alt={t("No sessions yet")}
                  style={{ width: "150px", marginBottom: "20px" }}
                />
                <h5>{t("No sessions here yet")}</h5>
              </div>
            )}
          </Row>
        </CardBody>
      </Card>
      {role === "admin" && (
        <>
          <AccountNameForm
            isOpen={accountModalOpen}
            toggle={toggleAccountModal}
            updateSessions={updateSessions}
          />
          <WebhookQrModal
            isOpen={qrCodeModalOpen}
            toggle={toggleQrCodeModal}
            existingSocket={socket}
            qrCode={state.qrCode}
            showQrCode={state.showQrCode}
          />
        </>
      )}
      <Modal isOpen={confirmModalOpen} toggle={toggleConfirmModal}>
        <ModalHeader toggle={toggleConfirmModal}>
          {t("Deletion Confirmation")}
        </ModalHeader>
        <ModalBody>
          {t("Are you sure you want to delete the session")}{" "}
          {sessionToDelete?.name}?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteSession} disabled={loading}>
            {loading ? t("Deleting...") : t("Delete")}
          </Button>
          <Button color="secondary" onClick={toggleConfirmModal}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default StatusPage;
