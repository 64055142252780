import Cookies from "js-cookie";
import { toast } from "react-toastify";

export interface StateType {
  loading: boolean;
  error: string | null;
  qrCode: string | null;
  showQrCode: boolean;
  status: string | null;
  [key: string]: any;
}

export const connectWebSocket = (
  WEBSOCKET_URL: string,
  onMessage: (event: MessageEvent) => void,
  onError: (event: Event) => void,
  onClose?: (this: WebSocket, ev: CloseEvent) => any,
  onOpen?: () => void
): WebSocket | null => {
  const companyName = Cookies.get("companyName");
  if (!companyName) {
    console.error("Company name is missing");
    return null;
  }

  const WEBSOCKET_URL_WITH_COMPANY = `${WEBSOCKET_URL}?companyName=${encodeURIComponent(companyName)}`;
  const socket = new WebSocket(WEBSOCKET_URL_WITH_COMPANY);

  socket.onmessage = onMessage;
  socket.onerror = onError;
  socket.onclose = onClose || null;

  socket.onopen = onOpen || (() => {
    console.log("WebSocket connection established");
  });

  return socket;
};

export const handleWebSocketMessage = <T extends StateType>(
  event: MessageEvent,
  setState: React.Dispatch<React.SetStateAction<T>>,
  toastMessage: string
) => {
  try {
    const message = event.data.startsWith("i")
      ? { qrCodeBase64: event.data, status: "SCAN_QR_CODE"}
      : JSON.parse(event.data);
    console.log("Received WebSocket message:", message);
    if (message.status === "SCAN_QR_CODE") {
      setState((prevState) => ({
        ...prevState,
        qrCode: "data:image/png;base64," + message.qrCodeBase64,
        showQrCode: true,
        webSocketLoading: false,
        loading: false,
        status: "SCAN_QR_CODE",
      }));
    } else if (message.status === "WORKING") {
      setState((prevState) => ({
        ...prevState,
        showQrCode: false,
        webSocketLoading: false,
        loading: false,
        status: "WORKING",
      }));
      toast.success(toastMessage);
    }
  } catch (error) {
    console.error("Failed to parse WebSocket message as JSON:", error);
  }
};

export const handleWebSocketError = <T extends StateType>(
  event: Event,
  setState: React.Dispatch<React.SetStateAction<T>>,
  errorMessage: string
) => {
  console.error("WebSocket error observed:", event);
  setState((prevState) => ({
    ...prevState,
    webSocketLoading: false,
    loading: false,
    error: errorMessage,
  }));
};

export const handleWebSocketClose = (
  socket: WebSocket | null,
  onClose?: (this: WebSocket, ev: CloseEvent) => any
): void => {
  if (socket) {
    socket.onclose = onClose || (() => console.log("WebSocket connection closed"));

    if (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING) {
      socket.close();
    } else {
      console.log("WebSocket is already closed or closing");
    }
  }
};
